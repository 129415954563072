import ProfileInfo from "../../components/settings/ProfileInfo";

const Profile = () => {
  return (
    <>
      <ProfileInfo />
    </>
  );
};

export default Profile;
